import { message } from 'antd'

import { request } from '@utils/request'
import { setItem } from '@utils/storage'

export const getSubscription = (params?: {
  email?: string
  //1页脚 2注册 3弹窗
  entrance?: 1 | 2 | 3
}) => {
  const { email, entrance = 1 } = params || {}
  return request
    .get<any>('/account/emailSubscribe', { ...params, entrance })
    .then((res) => {
      if (res.code === 505) {
        message.warning(`${res.message}`)
        throw new Error('')
      }
      return res.data
    })
    .catch((error) => {
      error?.message && message.error(`${error?.message}`)
      throw new Error(error?.message)
    })
}

export const getSubscriptionText = (params) => {
  return request
    .get<any>('/page/subscribeDesc', params)
    .then((res) => {
      setItem('subscriptionText', res.data)
      return res.data
    })
    .catch((error) => {
      //message.error(`${error?.message || 'something wrong!'}`)
    })
}
