import {
  AutoComplete,
  Button,
  ConfigProvider,
  Image,
  message,
  Modal
} from 'antd'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import winPixelHE from '@api/hernest/winPixel_m.bak'
import { useSearch } from '@hooks/index'
import useGlobalEvent, { dispatchGlobalEvent } from '@hooks/useGlobalEvent'
import Switch from '@ui-pc/Switch'
import {
  debounce,
  isDev,
  isHomeProject,
  isMobileProject,
  px2vw,
  throttle,
  validateEmail
} from '@utils/index'
import { getItem, setItem } from '@utils/storage'
import { Knife, Type } from '@utils/tool'
import winPixel, { pathnameToPage } from '@utils/winPixel'

import { getSubscription, getSubscriptionText } from './action'

import type { DefaultOptionType } from 'antd/es/select'
const defaultSuffix = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'aol.com',
  'live.com',
  'bigpond.com',
  'outlook.com',
  'icloud.com',
  'mac.com',
  'msn.com'
]

const Index = (props) => {
  const [options, setOptions] = React.useState<any[]>([])
  const subText = getItem('subscriptionText') || {}

  const [error, useError] = React.useState(false)

  const [emailValue, useEmail] = React.useState('')
  //const ref = React.useRef<string>()

  // winPixelHE.subscribe(
  //   emailValue,
  //   pathnameToPage(location.pathname),
  //   'Subscribe',
  //   'auto'
  // )

  const {
    data: subData = props.subData,
    isLoading,
    mutation: subscribe
  } = useSearch(
    () =>
      getSubscription({
        email: emailValue,
        entrance: 3
      }),
    ['mutation']
  )

  const handleSearch = (value: string) => {
    setOptions(() => {
      if (!value || value.includes('@')) {
        return []
      }

      return defaultSuffix.map((domain) => ({
        label: `${value}@${domain}`,
        value: `${value}@${domain}`
      }))
    })
  }

  const validate = () => {
    // const value = ref.current
    // console.log('valie', value)
    const isValidate = validateEmail(emailValue)
    useError(!isValidate)
    return isValidate
  }

  const onSub = async () => {
    if (!validate()) return
    winPixelHE.subscribe(
      emailValue,
      pathnameToPage(props?.pathname),
      'Subscribe',
      props?.position
    )
    await subscribe()
    dispatchGlobalEvent('subscriptionSucess')
  }

  React.useEffect(() => {
    if (!Type.isEmpty(subData?.couponCode)) {
      dispatchGlobalEvent('updateSubscriptionStatus')
    }

    if (subData?.isSubscribed) {
      message.success('Thanks for subscribing')
      dispatchGlobalEvent('updateSubscriptionStatus')
      props.onClose()
    }
  }, [subData?.couponCode, subData?.isSubscribed])

  const handleClick = (value) => {
    useEmail(value)
    setOptions([])
  }

  React.useEffect(() => {
    winPixel.custom(
      'viewSection',
      null,
      null,
      {
        page: pathnameToPage(props?.pathname),
        module: 'Subscribe',
        position: props?.position,
        isClick: props?.isClick
      },
      props?.position === 'Auto' ? 'exposure' : 'engagement'
    )
  }, [])
  // console.log('subText', subText)
  //console.log('subData', subData, Type.isEmpty(subData))
  return (
    <>
      <div className={`sub-popup-box ${isMobileProject && 'in-m'}`}>
        <div className='close' onClick={props.onClose}>
          <i className='iconfont icon-close' />
        </div>
        <div className='main-img'>
          <img src={subText.backgroundImage} />
        </div>
        <Switch either={Type.isEmpty(subData)}>
          {/* 准备订阅 */}
          <div className={`pre-sub ${isHomeProject ? 'home' : ''}`}>
            <div
              className='title'
              dangerouslySetInnerHTML={{ __html: subText.popTitle }}
            ></div>
            <span className='desc'>{subText.popSubtitle}</span>
            <div className={`email-area ${error && 'email-error'}`}>
              <i className='e-icon iconfont icon-ic_email' />
              <div className='auto-complete-wrap'>
                <AutoComplete
                  className='auto-complete'
                  style={{ width: '100%' }}
                  value={emailValue}
                  onSearch={handleSearch}
                  onChange={(value) => {
                    useEmail(value)
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      // 在这里处理按下回车键的逻辑
                      onSub()
                    }
                  }}
                  placeholder='Email Address'
                  options={isMobileProject ? [] : options}
                />
                <Switch
                  either={
                    Boolean(isMobileProject) &&
                    Boolean(emailValue) &&
                    options.length > 0
                  }
                >
                  <div className='email-snapshots'>
                    {options.map((data) => {
                      return (
                        <p onClick={() => handleClick(data.value)}>
                          {data.value}
                        </p>
                      )
                    })}
                  </div>
                </Switch>
              </div>
              <div className='error-text'>
                Please enter a valid email address.
              </div>
            </div>
            <Button loading={isLoading} className='button' onClick={onSub}>
              {subText.popInputText}
            </Button>
            <p
              className='policy-area'
              dangerouslySetInnerHTML={{ __html: subText.protocol }}
            ></p>
          </div>
          {/* 订阅成功 */}
          <div className='finish-sub'>
            <div
              className='title'
              dangerouslySetInnerHTML={{ __html: subText.popTitleAf }}
            ></div>
            <div className='line1'>
              <span className='desc'>{subText.popSubtitleAf}</span>
            </div>
            <div className={`coupon-area ${isHomeProject ? 'home' : ''}`}>
              <span className='title'>{subText.inputTip}</span>
              <div className={`coupon-desc ${isHomeProject ? 'home' : ''}`}>
                <CopyToClipboard
                  text={subData?.couponCode}
                  onCopy={() => {
                    message.success('Copied Successfully')
                  }}
                >
                  <p>
                    Code:{' '}
                    <span>
                      <span>{subData?.couponCode}</span>{' '}
                      <i className='iconfont icon-copy' />
                    </span>
                  </p>
                </CopyToClipboard>
                <p>{subText?.couponDesc}</p>
              </div>
            </div>
            <div className='line1'>
              <Button
                className={`button ${isHomeProject ? 'home' : ''}`}
                onClick={props.onClose}
              >
                {subText.popInputTextAf}
              </Button>
            </div>
          </div>
        </Switch>
      </div>
    </>
  )
}

type Props = {
  subData?
  pathname?: string
  position?: string
  isClick?: '0' | '1'
}
let lastPopup
const open = (props: Props = {}) => {
  lastPopup?.destroy()

  setItem('isPopup', true)
  setItem('startTime', Date.now())

  const close = () => {
    popup.destroy()
    location?.pathname === '/login' &&
      dispatchGlobalEvent('registerSuccessBack')

    dispatchGlobalEvent('openHeaderCartForTimestamp')
  }

  const popup = Modal.confirm({
    width: 'auto',
    title: null,
    footer: null,
    closeIcon: null,
    centered: true,
    onCancel: close,
    className: 'subs-popup',
    content: <Index {...props} onClose={close} />
  })

  lastPopup = popup
}

export const isSubcription = (data) => {
  return Number(data?.isSubscribe) === 1
}
function isDateChanged(timestamp1, timestamp2) {
  const date1 = new Date(timestamp1)
  const date2 = new Date(timestamp2)

  const day1 = date1.getDate()
  const day2 = date2.getDate()

  return day2 > day1 // 判断日期是否发生变化
}

function isTimeGap(timestamp1: number, timestamp2: number) {
  // 将时间戳转换为日期对象
  const date1 = new Date(timestamp1)
  const date2 = new Date(timestamp2)

  // 计算两个日期对象的差值（以毫秒为单位）
  const diff = Math.abs(Number(date2) - Number(date1))

  // 将差值转换为小时（1小时 = 60分钟 * 60秒 * 1000毫秒）
  const diffInHours = diff / (60 * 60 * 1000)

  // 判断差值是否大于3小时
  return diffInHours >= 1 && isDateChanged(timestamp1, timestamp2)
}

export const resetPopupStatus = () => {
  setItem('isPopup', false)
  setItem('isSubTipsClose', false)
  setItem('startTime', Date.now())
}

export const useOpenSubpopwindow = (isOpen: boolean = true) => {
  const subText = useSelector((state: any) => state.app.subscriptionText)
  const dispatch = useDispatch()

  const { mutation: getSubText } = useSearch(
    (params) => getSubscriptionText(params),
    ['mutation'],
    { cacheTimer: 300, key: 'getSubscriptionText' }
  )

  function getSubData() {
    //if (Type.isEmpty(userInfo)) return
    return getSubText({ terminal: isMobileProject ? 'm' : 'pc' }).then(
      (res) => {
        dispatch({
          type: 'app/updateSubscriptionText',
          payload: res
        })
        return res
      }
    )
  }

  React.useEffect(() => {
    if (!isOpen) return
    const openwindow = async (event: any) => {
      const isOpen = event?.target?.className?.indexOf('_opensubwindow_') > -1

      if (isOpen && Type.isEmpty(subText)) {
        await getSubData()
        open()
        return
      }

      if (isOpen) {
        open()
        return
      }
    }
    window.addEventListener('click', openwindow)
    return () => {
      window.removeEventListener('click', openwindow)
    }
  })

  return { getSubData }
}

export const useSubscription = () => {
  // const userInfo = useSelector((state: any) => state.app.userInfo)
  const subText = useSelector((state: any) => state.app.subscriptionText)
  const isLogin = useSelector((state: any) => state.app.isLogin)
  const isSub = isSubcription(subText)
  // true 不显示, false就显示.
  const getIsSubTipsClose = () =>
    Number(subText?.floatSubscribeEntry) != 1 || getItem('isSubTipsClose')

  const dispatch = useDispatch()
  const ref = React.useRef<HTMLDivElement>()
  const isClose = subText?.isClose
  const location = useLocation()

  const { getSubData } = useOpenSubpopwindow()

  React.useEffect(() => {
    getSubData()
  }, [isLogin])

  useGlobalEvent('requestSubData', () => getSubData())

  function autoOpen() {
    if (isClose) return
    if (Type.isEmpty(subText)) return
    if (isSubcription(subText)) {
      //subText.isSubscribe == 1 && setItem('isSubscribe', 1)
      return
    }
    const start = getItem('startTime') || Date.now()
    const currentTime = Date.now()
    //每3个小时重置弹窗条件
    if (isTimeGap(+start, currentTime)) {
      resetPopupStatus()
      return
    }

    const isPopup = getItem('isPopup')
    if (isPopup) return

    open({
      pathname: location?.pathname || '',
      position: 'Auto',
      isClick: '0'
    })

    ref.current.style.display = 'block'
  }

  React.useEffect(() => {
    // 每隔3小时弹1次，若今天弹过就不弹
    const timer = setInterval(autoOpen, 1000 * 5)
    //console.log('subTextrefresh', subText)
    return () => {
      clearInterval(timer)
    }
  }, [subText])

  useGlobalEvent('updateSubscriptionStatus', () => {
    const data = getItem('subscriptionText') || {}
    setItem('subscriptionText', { ...data, isSubscribe: true })
    dispatch({
      type: 'app/updateSubscriptionText',
      payload: { ...data, isSubscribe: true }
    })
    dispatchGlobalEvent('updateCouponData')
    ref.current.style.display = 'none'
  })

  // useGlobalEvent('openSubWindow', () => {
  //   open()
  // })

  React.useEffect(() => {
    // 滑动定时器
    let scrollingTimer = null
    // 获取固定元素
    const fixedElement = ref.current

    if (Type.isNever(fixedElement)) return
    if (isHomeProject) {
      fixedElement.style.display = 'block'
      return
    }
    const check = function () {
      if (getIsSubTipsClose()) return
      //console.log('fixedElement', fixedElement.getBoundingClientRect())
      // 获取文档的高度和视口的高度
      const docHeight = document.documentElement.scrollHeight
      const viewportHeight = window.innerHeight
      const scrollTop = window.scrollY
      const ratio = window.innerWidth / 750
      // 计算元素距离底部的距离
      const distanceFromBottom = docHeight - (scrollTop + viewportHeight)

      if (!isMobileProject) {
        if (
          distanceFromBottom < 493
          // || scrollTop < window.innerHeight
        ) {
          fixedElement.style.display = 'none'
        } else {
          fixedElement.style.display = 'block'
        }
      } else {
        fixedElement.style.display = 'block'
        if (
          distanceFromBottom <
          1920 * ratio
          // || scrollTop < window.innerHeight
        ) {
          fixedElement.style.display = 'none'
        } else {
          // 每次滚动时清除之前的定时器
          scrollingTimer && clearTimeout(scrollingTimer)
          // 如果是移动端设置定时器
          scrollingTimer = setTimeout(() => {
            fixedElement.style.display = 'block'
          }, 600)
        }
      }

      if (isMobileProject) {
        if (Knife.inRange(distanceFromBottom, [0, ratio * 1773]))
          return (fixedElement.style.bottom = `${
            ratio * 2146 - distanceFromBottom
          }`)
      } else {
        if (Knife.inRange(distanceFromBottom, [0, 522]))
          return (fixedElement.style.bottom = `${588 - distanceFromBottom}`)
      }
      // 如果元素距离底部的距离小于或等于300像素
      if (distanceFromBottom <= 522) {
        // 停止浮动，将元素固定在距离底部300像素的位置
        //fixedElement.style.bottom = '588'
      } else {
        // 恢复浮动
        fixedElement.style.bottom = isMobileProject ? px2vw(48) : '50'
      }
    }

    const t = throttle(check, 20)

    // 监听滚动事件
    window.addEventListener('scroll', t)
    //window.dispatchEvent(new Event('scroll'))
    return () => {
      scrollingTimer && clearTimeout(scrollingTimer)
      window.removeEventListener('scroll', t)
    }
  })

  const showTip = () => {
    const popup = Modal.confirm({
      width: 'auto',
      title: null,
      footer: null,
      closeIcon: null,
      centered: true,
      onCancel: () => {
        popup.destroy()
      },
      className: 'subs-popup',
      content: (
        <div className={`sub-slider-tip-confirm ${isMobileProject && 'in-m'}`}>
          <p>Subscribe to get {subText?.inputTip} off your first order.</p>
          <div>
            <Button
              onClick={() => {
                setItem('isSubTipsClose', true)
                popup.destroy()
                ref.current.style.display = 'none'
              }}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                open({
                  pathname: location.pathname || '',
                  position: 'Learn more',
                  isClick: '1'
                })
                popup.destroy()
              }}
            >
              Learn more
            </Button>
          </div>
          <div className='close' onClick={() => popup.destroy()}>
            <i className='iconfont icon-close' />
          </div>
        </div>
      )
    })
  }

  const closeTip = () => {
    try {
      setItem('isSubTipsClose', true)
      ref.current.style.display = 'none'
    } catch (error) {}
  }

  const siderTip = (
    <div ref={ref} className={`sub-sider-tip ${isMobileProject ? 'in-m' : ''}`}>
      <span
        onClick={() => {
          open({
            pathname: location.pathname,
            position: 'Float',
            isClick: '1'
          })
        }}
      >
        {subText?.buttonFloatContent || subText?.discountTip}
      </span>
      <div className='close' onClick={closeTip}>
        <i className='iconfont icon-close' />
      </div>
    </div>
  )

  if (isClose) return ''

  return !isSub && !getIsSubTipsClose() ? siderTip : ''
}

type Options = { code; onSubmit; onRemove }

export const showCheckoutSubscriptionTip = (options: Options) => {
  const { code, onSubmit, onRemove } = options

  const popup = Modal.confirm({
    width: 'auto',
    title: null,
    footer: null,
    // closeIcon: null,
    centered: true,
    onCancel: () => {
      popup.destroy()
    },
    className: 'subs-popup',
    content: (
      <div
        className={`sub-slider-tip-confirm checkout ${
          isMobileProject && 'in-m'
        }`}
        onClick={() => {
          popup.destroy()
        }}
      >
        <Switch either={code === 530}>
          <p>
            Oops! The coupon you are using is exclusive to first-time
            subscribers only.
          </p>
        </Switch>
        <Switch either={code === 531}>
          <p>
            Oops, the coupon you used has expired, please re-select the coupon
          </p>
        </Switch>
        <Switch either={code === 532}>
          <p>
            Oops, this coupon can only be used once. You've used this coupon
            before, please select it again.
          </p>
        </Switch>
        <Switch either={code === 530}>
          <p className='desc'>
            Your email address is not currently subscribed, will it subscribe
            for you and continue to checkout?
            <span>Unsubscribe anytime.</span>
          </p>
        </Switch>
        <div className='buttons'>
          <Switch either={code === 530}>
            <Button onClick={onSubmit}>Subscribe and Checkout</Button>
          </Switch>
          <Button onClick={onRemove}>Remove coupon and checkout</Button>
        </div>
      </div>
    )
  })
}

export const showsubscribedTips = () => {
  const popup = Modal.confirm({
    width: 'auto',
    title: null,
    footer: null,
    // closeIcon: null,
    centered: true,
    onCancel: () => {
      popup.destroy()
    },
    className: 'subs-popup',
    content: (
      <div
        className={`sub-slider-tip-confirm checkout ${
          isMobileProject && 'in-m'
        }`}
        onClick={() => {
          popup.destroy()
        }}
      >
        <p></p>
        <p className='desc'>
          The coupon you are using is for subscribers only and has been
          subscribed for you. By subscribing, you can get our latest products
          and promotions. Check your email to unsubscribe at any time.
        </p>
        <div className='buttons'>
          <Button onClick={() => popup.destroy()}>Got it</Button>
        </div>
      </div>
    )
  })
}

export default {
  open,
  useSubscription,
  showCheckoutSubscriptionTip,
  showsubscribedTips
}
